import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hemlet from 'react-helmet'

import Layout from '../components/Layout'

class SiteIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Hemlet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Hemlet>
        <h2>Miles Robson's Resume</h2>
        <p>
          <strong>
            <a href="mailto:milesdrobson@gmail.com">
              e-mail @ milesdrobson at gmail.com
            </a>
            <span> &#183; </span>
            <a href="https://drive.google.com/open?id=1pxjWYXTxrfItS7vfJbfNjlqsETrIr_OI">
              PDF of Resume
            </a>
          </strong>
        </p>
        <p>
          <strong>WORK EXPERIENCE</strong>
        </p>
        <p>
          <strong>
            Block - Senior Salesforce Engineer - (Remote)
          </strong>{' '}
          July 2021 - Present
        </p>
        <ul>
        <li>Supporting Cash App's Service Cloud org's platform health via improving architecture, logging, mocking enhancements, and development best practices.</li>
<li>Created an application to support case volume, allowing large intake without triggering Service Cloud hard limitations, involving triggers, batchable and invocable apex for declarative developers to enable safe PendingServiceRouting creation for skill based routing.</li>
<li>Mentoring and supporting a large enterprise team towards best practices for org health, involving deployment pipeline adjustments, peer reviews on declarative items, automated backups, and local tooling.</li>
<li>Aiding engineering and other integration partners in navigating the org's landscape, APIs, iframes, scoping out POCs, project possibilities, and debugging.</li>
<li>Engaging with internal enterprise procurement for RFI/RFC support, and implementation support.</li>
<li>Supporting business units in navigating Service Cloud’s backend objects for advocate management, skilling and reporting.</li>  
        </ul>
        <p></p>
        <p>
          <strong>
            Accenture Federal Services - Senior Salesforce Engineer - (Remote)
          </strong>{' '}
          March 2021 - July 2021
        </p>
        <ul>
          <li>
            Defining and building templates org-wide standards across multiple
            projects.
          </li>
          <li>Providing POCs of integrations and other efforts.</li>
        </ul>
        <p>
          <strong>
            Gravity Payments - Salesforce Engineer - Seattle, WA (Remote)
          </strong>{' '}
          March 2020 - March 2021
        </p>
        <ul>
          <li>Aided merchant onboarding, customer support, asset management, and ACH chargeback triage processes via Sales, Service, and Platform objects.</li>
          <li>Introduced lifecycle automation to internal SFDC platform.</li>
          <li>Context based integration updates with Platform Events to an internal lambda for merchant onboarding merchants to external partners.</li>
          <li>
          Rewrote internal boarding application from classic Flow to LWC.
          </li>
          <li>
            Utilized Nebula Logger across integration automations for aggressive tracking
            and error alerting.
          </li>
          <li>Created accounts payable/receivable integration jobs against Intacct's SOAP API</li>
        </ul>

        <p>
          <strong>
            Connection Enterprise Solutions - Salesforce Developer - Boca Raton,
            FL
          </strong>{' '}
          July 2018 - March 2020
        </p>

        <ul>
          <li>
            Handled discovery and development of migration efforts to Lightning, 
            including Visualforce pages translations to Aura Components, various declarative items, 
            Flows and Lightning Quick Actions, and Lightning-Only feature compatibility with business processes.
          </li>
          <li>
            Developed LWCs for reps to automate SFDC record creation referencing
            external data systems, creating opportunities off of quote records.
          </li>

          <li>
            Made LWCs for quick snapshots of a record’s health, from open orders
            on an account, to dollars spent on a given contact, using internal APIs.
          </li>

          <li>
            Translated external Pentaho ETL batch jobs to internal Salesforce
            batch Apex jobs with API callouts, error reporting with Platform
            Events, and notifications to Slack for alerting.
          </li>

          <li>
            Omni-channel Live Agent Snap-In development to identify appropriate
            accounts, contacts, and dynamic routing to related support groups.
          </li>

          <li>
            Went through discovery and development of migration efforts to
            Salesforce Lightning. Rewrote Visualforce pages to Aura Components,
            various declarative items, Flows and Lightning Quick Actions, and
            Lightning-Only feature compatibility with business processes.
          </li>

          <li>
            Introduced lifecycles with deployment automation using Git, Gerrit,
            ANT, and SFDX CLI.
          </li>
        </ul>

        <p>
          <strong>
            Office Depot - Developer, Sales Enablement Team - Boca Raton, FL
          </strong>{' '}
          Sept 2015 - July 2018
        </p>

        <ul>
          <li>
            Support of Salesforce batch processes for contract pricing, back-end
            de-duplication, data quality processes, and automated business rules
            for customer email notifications.
          </li>

          <li>
            Developed Salesforce automated user maintenance with PeopleSoft API.
          </li>

          <li>
            Built VFP for contract summaries of a given account, allowing line
            level review of SKU pricing, allowing maintenance and adjustments
            for margins as needed.
          </li>

          <li>
            Built Apex REST endpoints for inbound leads from retail stores for
            SMB opportunities
          </li>

          <li>
            Communities and LEX development for Partner Programs department.
          </li>

          <li>
            Built process to automate of external marketing files using GCS, S3,
            RCLONE, and Ansible Tower.
          </li>

          <li>
            Enhancements to Vendavo Compliance and Pricing app, primarily
            through Vendavo Scripting Language and Vendavo Query Language
            (VSL/VQL).
          </li>
          <li>
            Led initiatives to translate Dataloader shell batch jobs to IBM
            DataStage ETL and PLSQL jobs.
          </li>
          <li>
            Received Innovation award in Q3 2016 for design, development, and
            customer response of Vendavo Rebate enhancements.
          </li>
          <li>
            Led initiatives for tighter SLAs with integration teams, root cause
            analysis, and production triage.
          </li>
        </ul>

        <p>
          <strong>
            Key Brand Entertainment - Software Developer 1 - Fort Lauderdale, FL
          </strong>{' '}
          May 2015 - Sept 2015
        </p>

        <ul>
          <li>
            Development of RDLC, ASPX, JS, NUnit, WaitIn, and C# WCF services.
          </li>

          <li>
            Experience in MSSQL development, stored procedures and table
            amendments.
          </li>
        </ul>

        <p>
          <strong>Certifications</strong>
        </p>

        <ul>
          <li>Salesforce Platform Developer 1</li>
        </ul>

        <p>
          <strong>SIDE PROJECT WORK</strong>{' '}
          <strong>
            <a href="https://github.com/Confirm4Crit">GitHub</a>
          </strong>
        </p>

        <ul>
          <li>
            <strong>
              <a href="https://github.com/Confirm4Crit/awesome-lwc">
                Awesome-LWC
              </a>
            </strong>
            , a public GitHub repo of resources I help maintain.
          </li>
          <li>This website! GatsbyJS site hosted on Netify</li>

          <li>
            <strong>
              <a href="https://itch.io/queue/c/188585/gamkedo-club?game_id=243394">
                Steel Cavalry
              </a>
            </strong>
            , a mech sidescroller made in Unity as part of{' '}
            <strong>
              <a href="https://gamkedo.com/">Gamkedo</a>
            </strong>{' '}
            club
          </li>

          <li>
            Implemented a binary-encoded image manipulator using Motorola 68000
            assembly language
          </li>

          <li>
            Created a basic shopping website using HTML. JavaScript, and PHP
          </li>

          <li>
            Aided in the development of senior aid Android app, managed research
            of falling detection algorithms and implementation, and senior
            usability research.
          </li>
        </ul>

        <p>
          <strong>EDUCATION</strong>
        </p>

        <ul>
          <li>
            Florida Atlantic University – Boca Raton, FL 2013-2015 Bachelor of
            Science in Computer Science GPA: 3.4
            <ul>
              <li>
                Inducted into Upsilon Pi Epsilon International Computer Science
                Honor Society (Top 10% of class 2014)
              </li>
            </ul>
          </li>
        </ul>
      </Layout>
    )
  }
}

export default SiteIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
